.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.Home-header {
  height: 80px;
}

#map {
  height: 100%;
}

.Data-div {
  height: 300px;
  width: 700px;
  border: 1px solid black;
}


/** CHECKBOX */
.check {
    @apply inline-block relative w-[18px] h-[18px] mr-2.5 my-2.5 bg-transparent rounded-xl ;
    /*box-shadow: 0 0 0 0 #c4cbe8;*/
    transition: box-shadow 100ms ease-in;
}
.check:hover > .check__input:not([disabled]){
    /*box-shadow: 0 0 0 10px #c4cbe8;*/
    @apply cursor-pointer
}
.check__input:focus {
    outline: none;
}
.check__input {
    @apply absolute w-[1.25rem] h-[1.25rem] z-10 top-0 left-0 opacity-0 border;
}
.check__input:disabled {
    @apply absolute w-[1.25rem] h-[1.25rem] z-10 top-0 left-0 opacity-0 border cursor-not-allowed bg-white;
}
.check__box {
    @apply absolute w-0 h-0 p-[0.625rem] top-0 left-0 rounded-md bg-white bg-no-repeat block border-2 border-primary;
    transition: background-color 150ms ease-in, border-color 150ms ease-in;
    /*box-shadow: 0px 3px 6px #00000029;*/
}
.check .check__input:focus + .check__box {
    @apply border-accent;
    -webkit-animation: 350ms cubic-bezier(0.694, 0.0482, 0.335, 1) 1 normal forwards focusRingAnimate !important;
    animation: 350ms cubic-bezier(0.694, 0.0482, 0.335, 1) 1 normal forwards focusRingAnimate !important;
}
.check .check__input:disabled + .check__box {
    @apply cursor-not-allowed border-gray-400 bg-gray-200;
}
.check .check__input:checked + .check__box {
    @apply bg-center bg-no-repeat bg-[url("./asset/icons/check.svg")];
}



.sr-only {
  display: none;
}

.matte-text {
  @apply text-xl mb-2.5
}

.matte-button {
  @apply bg-[#191F56] rounded-lg w-full justify-center content-center p-2 text-xl mb-2.5 font-semibold hover:bg-[#474F9A] active:bg-[#5F67B5] disabled:bg-[#DADADC]
}

.react-datepicker-ignore-onclickoutside {
  @apply w-[17.5rem] border border-[#191F56]
}

.leaflet-control-container:first-child {
    @apply z-50 right-40 bg-red-400
}

.leaflet-control-zoom.leaflet-bar.leaflet-control {
    border: 2px solid #233965!important;
}

.leaflet-control-zoom-in {
    background-color: #009BDE!important;
    color: white!important;
    border-bottom: 1px solid #233965!important;
}
.leaflet-control-zoom-out {
    background-color: #009BDE!important;
    color: white!important;
    border-top: 1px solid #233965!important;
}

.security-timepicker {
    @apply focus-visible:outline-none text-white bg-transparent w-[9rem] text-xs
}

input[type="datetime-local"]::-webkit-calendar-picker-indicator {
    background: none;
}

.cv-input {
    @apply h-[20px] py-1 text-xs rounded-lg bg-transparent focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75;
}

.cv-input:focus {

}

.btn {
    @apply bg-[#009BDE] text-xs py-2 w-full rounded-md text-white font-bold disabled:bg-[#EBEBEB] disabled:text-[#B9B9B9]
}

/* SCROLL BAR */
::-webkit-scrollbar {
    width: 9px;
}
/* Track */
::-webkit-scrollbar-track {
    @apply rounded-md bg-[#EDEDED]
}
/* Handle */
::-webkit-scrollbar-thumb {
    @apply rounded-md bg-[#233965]
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    @apply bg-[#009BDE];
}

.leaflet-control-container .leaflet-top.leaflet-right {
    @apply p-8
}
.leaflet-control-container .leaflet-top.leaflet-left {
    @apply absolute -top-10
}